import React from 'react'
import Layout from 'src/components/layout/layout'
import SEO from 'src/components/seo'
import { usePrivateRoute } from 'src/utils/auth'
import ScorecardTemplatesDetails from 'src/components/scorecard-templates-details/scorecard-templates-details'

const CreateTemplate = props => {
  usePrivateRoute()
  return (
    <Layout>
      <SEO title="Create Scorecard Template" />
      <ScorecardTemplatesDetails {...props} />
    </Layout>
  )
}

export default CreateTemplate
